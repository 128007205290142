<template>
  <AssessmentForm
    :assessmentDate="form.assessmentDate"
    :score="score"
    :rating="rating"
    :assessmentId="form.assessmentId"
    :assessmentType="assessmentType"
    :form="form"
    :readonly="readonly"
    :saveDisabled="saveDisabled"
    @setResidentData="setResidentData"
    @setAssessmentDate="setAssessmentDate"
    @mapServerData="mapServerData"
    @dataSaved="dataSaved"
  >
    <b-field label="Mobility" horizontal message="on level surfaces" class="label-long">
      <enum-drop-down v-model="form.mobility" :data="barthel.MobilityLookup" mediumWidth :disabled="readonly" />
    </b-field>
    <b-field label="Transfers" horizontal message="bed to chair and back" class="label-long">
      <enum-drop-down v-model="form.transfers" :data="barthel.TransfersLookup" mediumWidth :disabled="readonly" />
    </b-field>
    <b-field label="Stairs" horizontal class="label-long">
      <enum-drop-down v-model="form.stairs" :data="barthel.StairsLookup" mediumWidth :disabled="readonly" />
    </b-field>
    <b-field label="Bowels" horizontal class="label-long">
      <enum-drop-down v-model="form.bowels" :data="barthel.BowelsLookup" mediumWidth :disabled="readonly" />
    </b-field>
    <b-field label="Bladder" horizontal class="label-long">
      <enum-drop-down v-model="form.bladder" :data="barthel.BladderLookup" mediumWidth :disabled="readonly" />
    </b-field>
    <b-field label="Toilet" horizontal class="label-long">
      <enum-drop-down v-model="form.toilet" :data="barthel.ToiletLookup" mediumWidth :disabled="readonly" />
    </b-field>
    <b-field label="Bathing" horizontal class="label-long">
      <enum-drop-down v-model="form.bathing" :data="barthel.BathingLookup" mediumWidth :disabled="readonly" />
    </b-field>
    <b-field label="Grooming" horizontal class="label-long">
      <enum-drop-down v-model="form.grooming" :data="barthel.GroomingLookup" mediumWidth :disabled="readonly" />
    </b-field>
    <b-field label="Dressing" horizontal class="label-long">
      <enum-drop-down v-model="form.dressing" :data="barthel.DressingLookup" mediumWidth :disabled="readonly" />
    </b-field>
    <b-field label="Feeding" horizontal class="label-long">
      <enum-drop-down v-model="form.feeding" :data="barthel.FeedingLookup" mediumWidth :disabled="readonly" />
    </b-field>
  </AssessmentForm>
</template>

<script>
import AssessmentForm from "@/views/assessments/AssessmentForm";
import EnumDropDown from "@/components/EnumDropDown";

import AssessmentType from "@/enums/assessmentType";
import assessmentMixin from "@/mixins/assessmentMixin";

import barthel from "@/enums/barthel.js";

export default {
  components: { AssessmentForm, EnumDropDown },
  mixins: [assessmentMixin],
  data() {
    return {
      assessmentType: AssessmentType.Enum.Barthel,
      barthel: barthel,
      form: this.getClearFormObject()
    };
  },

  computed: {
    score() {
      return (
        this.form.mobility +
        this.form.transfers +
        this.form.stairs +
        this.form.bowels +
        this.form.bladder +
        this.form.toilet +
        this.form.bathing +
        this.form.grooming +
        this.form.dressing +
        this.form.feeding
      );
    },
    rating() {
      if (this.score < 6) return barthel.Enum.Max;
      if (this.score < 11) return barthel.Enum.High;
      if (this.score < 16) return barthel.Enum.Medium;
      if (this.score < 20) return barthel.Enum.Low;
      return barthel.Enum.Independent;
    }
  },

  methods: {
    getClearFormObject() {
      return {
        assessmentId: this.id,
        residentId: this.residentId,
        assessmentDate: new Date(),
        gender: null,
        age: null,

        mobility: null,
        transfers: null,
        stairs: null,
        bowels: null,
        bladder: null,
        toilet: null,
        bathing: null,
        grooming: null,
        dressing: null,
        feeding: null
      };
    }
  }
};
</script>
